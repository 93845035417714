import React, { useState, useEffect } from "react";
import instafeed from "react-instafeed";
import imgBg from "../../assets/icons/icon_3.png";

const InstagramFeed = () => {
  const options = {
    limit: 10,
    accessToken: "1126446983.106300e.ae5db65c795441df9681114a2823437d",
    clientId: "106300e3d43f4dda840024d57d77d2f3",
    get: "user", // popular, user
    locationId: null,
    resolution: "standard_resolution", // thumbnail, low_resolution, standard_resolution
    sortBy: "none", // none, least-commented, least-liked, least-recent, most-commented, most-liked, most-recent, random
    tagName: null,
    userId: 1126446983
  };

  const [array, setArray] = useState([]);

  useEffect(() => {
    if (array && array.length === 0) {
      const getInstafeed = instafeed(options);
      getInstafeed.then(
        function(result) {
          console.log(result); // "Promise resolved successfully"
          setArray(result.data);
        },
        err => {
          console.log(err); // Error: "Promise rejected"
        }
      );
    }
  });

  return (
    <div className="card-columns">
      {array ? (
        array.map(item => (
          <div key={item.id} className="card">
            <a href={item.link}>
              <img
                className="w-100"
                src={item.images.standard_resolution.url}
                alt={item.caption.text}
              />
            </a>
          </div>
        ))
      ) : (
        <h6>Loading images, please wait</h6>
      )}
    </div>
  );
};

export default () => {
  return (
    <div className="ig-feed_part padding_bottom">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-7">
            <div className="section_title text-center">
              <h2>Follow Us on Instagram</h2>
            </div>
          </div>
        </div>
        <div className="row gallery-item">
          <div className="col-md-12">
            <InstagramFeed />
          </div>
        </div>
      </div>
      <img src={imgBg} className="animation_icon_3" alt="" />
    </div>
  );
};
